<script setup>
    import { Form, Field } from "vee-validate";
    import * as Yup from "yup";
    import {defineProps, onBeforeMount, onMounted, ref,computed} from "vue";
    import SectionContainer from "../containers/SectionContainer.vue";
    import Text from "./Text.vue";
    import Button from "./Button.vue";
    import ElementContaier from "../containers/ElementContaier.vue";
    import FindStudioSearch from "./FindStudioSearch.vue";
    import { KlaviyotrackEvent, EmailIntroOfferVisit, getfreeclassByStudioId } from "../../mixins/api";
    import {useRoute, useRouter} from "vue-router";
    import { triggerTrackEvents } from "../../mixins/events_handler";

    const route = useRoute();
    const queryParams = route.query;
    const router = useRouter();
    const isMobile = ref(false);
    const FreeclassUrlStarted = process.env.VUE_APP_FreeclassUrl_URL;
    const freeclass = ref([]);
    const checkIsMobile = () => {
        let width = window.innerWidth || 
        document.documentElement.clientWidth || 
        document.body.clientWidth;
        isMobile.value = width < 640 ? true : false;
    };
    onBeforeMount(() => {
        window.addEventListener("resize", checkIsMobile);
    });
    const getfreeclassonStarted = () => {
        console.log('currentStudio', props?.currentStudio, studioId.value)
        getfreeclassByStudioId(studioId.value).then((response) => {
          freeclass.value = response;
        });
    };
     // Current Studio Id
     const studioId = computed(() => {
        return props?.currentStudio?.ID
            ? props?.currentStudio?.ID
            : 0;
    });
    onMounted(() => {
        checkIsMobile();
        getfreeclassonStarted();
        studio.value = props.currentStudio
    });
    const props = defineProps({
      redirectedToCheckout: Boolean,
      redirectedToStudio: Boolean,
      currentStudio: {
        type: Object
    }
    })

    const schema = Yup.object().shape({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        email: Yup.string().required("Email is required").email("Email is invalid"),
        phonenumber: Yup.string().required("Phone number is required"),
    });
    const customer = ref({
        firstname: "",
        lastname: "",
        email: sessionStorage.getItem('email') || "",
        phonenumber: "",
    });
    const containsFreeClass  = (productname) => {
        const mainStringLower = productname.toLowerCase();
        return mainStringLower.includes("free")

        };
    const studio = ref();
    const checkoutUrl = process.env.VUE_APP_CHECKOUT_URL;
    const onSubmit = () => {
        if(studio.value?.IsActive== false){
            alert("Studio is closed. Purchase not allowed!");
            return;
        }
        if(!props.currentStudio && !(props.currentStudio?.IsActive==false)){
            alert("Studio is closed. Purchase not allowed!");
            return;
        }
        if(studio.value?.ID) {
          if(!props.currentStudio && !props.currentStudio?.ID) {
            triggerTrackEvents('Home', 'Find Studio Popup', 'home_optin')
          } else {
            triggerTrackEvents('Studio', 'Find Studio Popup', 'optin_lead')
          }
        try{
          EmailIntroOfferVisit(customer.value, studio.value).then( () => {
            });
        }
        catch(exception)
        {}
      try{
            KlaviyotrackEvent(customer.value, studio.value).then( () => {
              const params = {
                ...queryParams,
                fname: customer.value.firstname,
                lname: customer.value.lastname,
                email: customer.value.email,
                phone: customer.value.phonenumber
              }
              if(props.redirectedToCheckout) {
                var url =redirectTogetfreeclassUrl();
                if(url!=""){
                    window.location.href = url;
                }
                else{
                const paramsQuery = Object
                    .keys(params)
                    .map(value => `${value}=${encodeURIComponent(params[value])}`)
                    .join('&');
                window.location.href = checkoutUrl + studio.value.url_slug +
                    '?' + paramsQuery;
                }
              } else if(props.redirectedToStudio) {
                if(!(redirectTogetfreeclassUrl())){
                router.push({path: '/' + studio.value.url_slug, query: params})
                }
              } else {
                if(!(redirectTogetfreeclassUrl())){
                router.push({path: '/' + studio.value.url_slug + '/signup', query: params})
                }
              }
            });
        }
        catch(exception)
        {}
      
        } else {
            console.log('Select studio')
        }
    };

    const selectedStudio = (studioDetails) => {
        studio.value = studioDetails;
    }
    const redirectTogetfreeclassUrl=()=> {
   var url="";
        freeclass.value.forEach(product => {
                if(containsFreeClass(product.type) && containsFreeClass(product.category))
                {
                    url= (FreeclassUrlStarted + props?.currentStudio?.url_slug) ;
                  
                }
            });
        return url;
  }
</script>

<template>
    <div class="row">
        <SectionContainer widthSize="50">
            <div class="getstarted-left">
                <div class="getstarted-left-top">
                    <ElementContaier>
                        <Text type="H4" text="Make today your Day One with iLoveKickboxing." />
                    </ElementContaier>
                    <ElementContaier>
                        <Text text="Stop waiting for tomorrow to kick off your health and wellness journey with us. Let’s get started." class="mb-24 font-size-15" />
                    </ElementContaier>
                    <template v-if="!currentStudio && !currentStudio?.ID">
                        <ElementContaier>
                            <Text type="H4" text="FIND YOUR STUDIO" class="mb-16" />
                        </ElementContaier>
                        <ElementContaier class="getstarted-findstudio">
                            <FindStudioSearch @selectedStudio="selectedStudio" />
                        </ElementContaier>
                    </template>
                </div>
                <template v-if="!isMobile">
                    <div class="getstarted-left-bottom">
                        <ElementContaier>
                            <Text text="By submitting this form, I agree to receive emails, text messages, and other communications regarding offers and promotions from iLoveKickboxing." class="mb-10 font-size-15" />
                        </ElementContaier>
                    </div>
                </template>
            </div>
        </SectionContainer>
        <SectionContainer widthSize="50">
            <ElementContaier>
                <Text 
                    type="H4" 
                    text="We’d love to know a little bit more about you."
                    class="mb-24"
                />
            </ElementContaier>
            <ElementContaier>
                <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                >
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.firstname"
                            name="firstname"
                            id="firstname"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.firstname }"
                        />
                        <label>First Name</label>
                        <div class="invalid-feedback">{{ errors.firstname }}</div>
                    </div>
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.lastname"
                            name="lastname"
                            id="lastname"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.lastname }"
                        />
                        <label>Last Name</label>
                        <div class="invalid-feedback">{{ errors.lastname }}</div>
                    </div>
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.email"
                            name="email"
                            id="email"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.email }"
                        />
                        <label>Email</label>
                        <div class="invalid-feedback">{{ errors.email }}</div>
                    </div>
                    <div class="form-group">
                        <Field
                            type="text"
                            v-model="customer.phonenumber"
                            name="phonenumber"
                            id="phonenumber"
                            class="form-control"
                            placeholder=""
                            :class="{ 'is-invalid': errors.phonenumber }"
                        />
                        <label>Phone Number</label>
                        <div class="invalid-feedback">{{ errors.phonenumber }}</div>
                    </div>
                    <Button type="submit" text="Let's Do This!" class="col-100" />
                </Form>
                <template v-if="isMobile">
                    <ElementContaier>
                        <Text text="By submitting this form, I agree to receive emails, text messages, and other communications regarding offers and promotions from iLoveKickboxing." class="mt-24 mb-10 font-size-15" />
                    </ElementContaier>
                </template>
            </ElementContaier>
        </SectionContainer>
    </div>
</template>