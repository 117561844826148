<script setup>
import { onBeforeMount, defineProps, computed, ref, onMounted } from "vue";
import StudioProducts from "@/components/elements/StudioProducts.vue";
import StudioDetails from "@/components/elements/StudioDetails.vue";
import Text from "@/components/elements/Text.vue";
import Button from "@/components/elements/Button.vue";
import Image from "@/components/elements/Image.vue";
import Carousel from "@/components/elements/Carousel.vue";
import Search from "../elements/Search.vue";
import ElementContaier from "../containers/ElementContaier.vue";
import LocationAddress from "../elements/LocationAddress.vue";
import WorkingHours from "../elements/WorkingHours.vue";
import LocationMap from "../elements/LocationMap.vue";
import Amenities from "../elements/Amenities.vue";
import ScheduleWidget from "../elements/ScheduleWidget.vue";
import ReferrizerWidget from "../elements/ReferrizerWidget.vue";
import FAQs from "../elements/FAQs.vue";
import Punches from "../elements/Punches.vue";
import ClassStructure from "../elements/ClassStructure.vue";
import Instructors from "../elements/Instructors.vue";
import {  getfreeclassByStudioId } from "../../mixins/api";
import Modal from "@/components/elements/Modal.vue";
import CloseIcon from "@/assets/svg/CloseIcon.vue";
import PlayIcon from "@/assets/svg/PlayIcon.vue";
import SectionContainer from "@/components/containers/SectionContainer.vue";
import ArrowRightSmallIcon from "../../assets/svg/ArrowRightSmallIcon.vue";
import GetStarted from "../elements/GetStarted.vue";
import ContactForm from "../elements/ContactForm.vue";
import Fighter from "../elements/Fighter.vue";
import { useRoute, useRouter } from "vue-router";
import IframeVideoCard from "../elements/IframeVideoCard.vue";
import StudioSchedule from "@/components/elements/StudioSchedule";
import Loader from "@/components/elements/Loader.vue";
import { boolean } from "yup";

const route = useRoute();
const queryParams = route.query;
const router = useRouter();

const props = defineProps({
  element: {
    type: Object,
  },
  currentStudio: {
    type: Object,
  },
  IsheroBaner: {
    type: Object,
  },
});
const FreeclassUrlStarted = process.env.VUE_APP_FreeclassUrl_URL;
const freeclassnav = ref([]);
const isModalVisible = ref(false);
const showModal = () => {
  isModalVisible.value = true;
};
const closeModal = () => {
  isModalVisible.value = false;
};

const isModalOption = ref(false);
const showModalOption = () => {
  isModalOption.value = true;
};
const closeModalOption = () => {
  isModalOption.value = false;
};
const isModalContact = ref(false);
const showModalContact = () => {
  isModalContact.value = true;
};
const closeModalContact = () => {
  isModalContact.value = false;
};
const isMobile = ref(false);
const isDesktop = ref(false);
const checkIsMobile = () => {
  let width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
  isDesktop.value = width > 641 ? true : false;
};
const containsFreeClass  = (productname) => {
        const mainStringLower = productname.toLowerCase();
        return mainStringLower.includes("free")

        };
        const studioId = computed(() => {
        return props?.currentStudio?.ID
            ? props?.currentStudio?.ID
            : 0;
    });
    const getfreeclassonnav = () => {
        console.log('currentStudio', props?.currentStudio, studioId.value)
        getfreeclassByStudioId(studioId.value).then((response) => {
            freeclassnav.value = response;
        });
        return true;
    };
    const redirectTogetfreeclassUrl=()=> {
        var url="";
        freeclassnav.value.forEach(product => {
                if(containsFreeClass(product.type) && containsFreeClass(product.category))
                {
                    url= (FreeclassUrlStarted + props?.currentStudio?.url_slug) ;
                  
                }
            });
        return url;
     }
    function OLSMarGetintroClick() {
        var url =redirectTogetfreeclassUrl();
        if(url != ""){
            window.location.href = url;
        }
        else{
            var elem = document
                .getElementById("studio_products");
            elem
                .scrollIntoView();
        }
    }

onBeforeMount(() => {
  window.addEventListener("resize", checkIsMobile);
});
onMounted(() => {
  checkIsMobile();
});

// Element Text
const elementText = computed(() => {
  let studioCity = "";
  if (props?.currentStudio?.ID) {
    studioCity = props?.currentStudio?.City;
  }
  return props?.element?.element_text
    ? props?.element?.element_text.replace(/##STUDIO-CITY##/g, studioCity)
    : "";
});

// Element Source
const elementSource = computed(() => {
  let studioUrlSlug = "";
  if (props?.currentStudio?.ID && props?.element?.element_source) {
    studioUrlSlug = props?.currentStudio?.url_slug;
  }
  return props?.element?.element_source
    ? props?.element?.element_source.replace(
        /##STUDIO-URL-SLUG##/g,
        studioUrlSlug
      )
    : "";
});

const elementSourceHash = computed(() => {
  let studioUrlSlug = "";
  if (props?.currentStudio?.ID && props?.element?.element_source) {
    studioUrlSlug = props?.currentStudio?.url_slug;
  }
  let elementSourceStr = props?.element?.element_source
    ? props?.element?.element_source.replace(
        /##STUDIO-URL-SLUG##/g,
        studioUrlSlug
      )
    : "";

  let index = elementSourceStr.indexOf("#");
  return index > 0 ? elementSourceStr.substring(index) : "";
});

const displayElementRoute = computed(() => {
  if (props?.currentStudio?.ID) {
    if (props?.currentStudio?.mbServices.length > 0) {
      return true;
    } else if (props?.currentStudio?.mbContracts.length > 0) {
      return true;
    }
  }
  return false;
});

const displayElementSourceHash = computed(() => {
  if (props?.currentStudio?.ID) {
    if (props?.currentStudio?.mbServices.length > 0) {
      return "#studio_services";
    } else if (props?.currentStudio?.mbContracts.length > 0) {
      return "#studio_contracts";
    }
  }
  return "";
});
</script>
<template>
  <ElementContaier
    :class="element.class"
    :style="{
      margin: element.element_margin,
      padding: element.element_padding,
    }"
  >
    <template v-if="element.element_type === 'TEXT'">
      <template v-if="isDesktop && element.device === 'DESKTOP'">
        <Text
          :class="[
            'element-text ',
            element.element_text_alingment &&
              'text-align-' + element.element_text_alingment,
            element.element_text_size &&
              'font-size-' + element.element_text_size,
          ]"
          :text="elementText"
          :type="element.element_sub_type"
          :style="[element.element_style]"
        />
      </template>
      <template v-else-if="isMobile && element.device === 'MOBILE'">
        <Text
          :class="[
            'element-text ',
            element.element_text_alingment &&
              'text-align-' + element.element_text_alingment,
            isMobile &&
              element.element_text_mobile_size &&
              'font-size-mobile-' + element.element_text_mobile_size,
          ]"
          :text="elementText"
          :type="element.element_sub_type"
          :style="[element.element_style]"
        />
      </template>
      <template v-else-if="element.device === 'ALL'">
        <Text
          :class="[
            'element-text ',
            element.element_text_alingment &&
              'text-align-' + element.element_text_alingment,
            element.element_text_size &&
              'font-size-' + element.element_text_size,
            isMobile &&
              element.element_text_mobile_size &&
              'font-size-mobile-' + element.element_text_mobile_size,
          ]"
          :text="elementText"
          :type="element.element_sub_type"
          :style="[element.element_style]"
        />
      </template>
    </template>
    <template v-else-if="element.element_type === 'BUTTON'">
      <template v-if="element.element_alt == 'hero_banner_watch_video'">
        <SectionContainer>
          <Button styled="circle" @click="showModal" :text="elementText">
            <template #icon>
              <span class="playicon">
                <PlayIcon />
              </span>
            </template>
          </Button>
          <Modal v-if="isModalVisible">
            <template #body>
              <span class="modal-close" @click="closeModal">
                <CloseIcon />
              </span>
              <div class="video-container">
                <iframe
                  width="854"
                  height="480"
                  :src="element.element_source"
                  title="Watch Video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
            </template>
          </Modal>
        </SectionContainer>
      </template>
      <!-- Other button templates -->
    </template>
    <!-- <template v-else-if="element.element_type === 'OPTION_MODAL'">
      <a
        href="#studio_products"
        v-if="currentStudio?.url_slug === 'oldsmar'"
        style="text-decoration: none"
      >
        <Button class="nav-link" text="Get Intro Offer" />
      </a>
      <Button
        v-if="
          currentStudio?.url_slug !== 'oldsmar' &&
          currentStudio?.url_slug !== 'saskatoon'
        "
        @click="showModalOption"
        :text="elementText"
        :styled="element.element_sub_type"
      />

      <a
        href="https://www.ilovekickboxing.com/freeclass/?q=saskatoon"
        v-if="currentStudio?.url_slug === 'saskatoon'"
      >
        <Button
          text="Get Free Class"
          :styled="element.element_sub_type"
        />
      </a>

      <Modal modalLarge="true" v-if="isModalOption">
        <template #body>
          <span class="modal-close" @click="closeModalOption">
            <CloseIcon />
          </span>
          <GetStarted
            :redirectedToCheckout="true"
            :redirectedToStudio="false"
            :currentStudio="currentStudio"
          />
        </template>
      </Modal>
    </template> -->
    <template v-else-if="element.element_type === 'CONTACT_FORM_MODAL'">
      <Button
        @click="showModalContact"
        :text="elementText"
        :styled="element.element_sub_type"
      />

      <Modal modalSmall="true" v-if="isModalContact">
        <template #body>
          <span class="modal-close" @click="closeModalContact">
            <CloseIcon />
          </span>
          <Text
            type="H4"
            text="Contact Corporate HQ - HQ is not able to assist with booking classes or personal membership questions."
            class="mb-24"
          />
          <ContactForm />
        </template>
      </Modal>
      </template>
        <template v-else-if="element.element_type === 'OPTION_MODAL'">
     
            <!-- <a href="#studio_products" v-if="currentStudio?.url_slug ==='oldsmar'" style="text-decoration:none;">
                <Button 
                    class="nav-link"
                    text="Get Intro Offer"
                /> 
            </a> -->
        <Button   @click="showModalOption" :text="elementText" :styled="element.element_sub_type" />
            
            <a href="https://www.ilovekickboxing.com/freeclass/?q=saskatoon"
                v-if="currentStudio?.url_slug === 'saskatoon'">
                <Button text="Get Free Class" style="color: black; background-color: white; border-color: white;" />
            </a>
      
            <Modal modalLarge="true" v-if="isModalOption">
                <template #body>
                    <span class="modal-close" @click="closeModalOption">
                        <CloseIcon />
                    </span>
                    <GetStarted :redirectedToCheckout="true" :redirectedToStudio="false"
                        :currentStudio="currentStudio" />
                </template>
            </Modal>
        </template>
        <template v-else-if="element.element_type === 'CONTACT_FORM_MODAL'">
            <Button @click="showModalContact" :text="elementText" :styled="element.element_sub_type" />
            <Modal modalSmall="true" v-if="isModalContact">
                <template #body>
                    <span class="modal-close" @click="closeModalContact">
                        <CloseIcon />
                    </span>
                    <Text type="H4"
                        text="Contact Corporate HQ - HQ is not able to assist with booking classes or personal membership questions."
                        class="mb-24" />
                    <ContactForm />
                </template>
            </Modal>
        </template>
        <template v-else-if="element.element_type === 'STUDIO_SEARCH_MODAL'">
            <Button @click="showModalOption" :text="elementText" :styled="element.element_sub_type" />
            <Modal modalLarge="true" v-if="isModalOption">
                <template #body>
                    <span class="modal-close" @click="closeModalOption">
                        <CloseIcon />
                    </span>
                    <div class="studio-search-modal">
                        <Search />
                    </div>
                </template>
            </Modal>
        </template>
        <template v-else-if="element.element_type === 'BUTTON_LINK'">
            <router-link :to="{ path: '/' + elementSource, query: queryParams, hash: elementSourceHash }">
                <Button :text="elementText" :styled="element.element_sub_type" />
            </router-link>
        </template>
        <template v-else-if="element.element_type === 'VIEW_PRICING_LINK'">
            <template v-if="displayElementRoute">
                <router-link :to="{ path: '/' + elementSource, query: queryParams, hash: displayElementSourceHash }">
                    <Button :text="elementText" :styled="element.element_sub_type" />
                </router-link>
            </template>
        </template>
        <template v-else-if="element.element_type === 'BUTTON_EXTERNAL_LINK'">
            <a :href="elementSource" target="_blank">
                <Button :text="elementText" :styled="element.element_sub_type" />
            </a>
        </template>
        <template v-else-if="element.element_type === 'IMAGE'">
            <template v-if="isDesktop && element.device === 'DESKTOP'">
                <Image :src="element.element_source" :text="element.element_alt"
                    :imageStyled="element.element_sub_type" />
            </template>
            <template v-else-if="isMobile && element.device === 'MOBILE'">
                <Image :src="element.element_source" :text="element.element_alt"
                    :imageStyled="element.element_sub_type" />
            </template>
            <template v-else-if="element.device === 'ALL'">
                <Image :src="element.element_source" :text="element.element_alt"
                    :imageStyled="element.element_sub_type" />
            </template>
        </template>
        <template v-else-if="element.element_type === 'MODAL_VIDEO'">
            <template v-if="isDesktop && element.device === 'DESKTOP'">
                <IframeVideoCard :src="element.element_poster" :text="element.element_alt"
                    :videoSource="element.element_source" />
            </template>
            <template v-else-if="isMobile && element.device === 'MOBILE'">
                <IframeVideoCard :src="element.element_poster" :text="element.element_alt"
                    :videoSource="element.element_source" />
            </template>
            <template v-else-if="element.device === 'ALL'">
                <IframeVideoCard :src="element.element_poster" :text="element.element_alt"
                    :videoSource="element.element_source" />
            </template>
        </template>
        <template v-else-if="element.element_type === 'CAROUSEL' && element.images.length > 0">
            <template v-if="isDesktop && element.device === 'DESKTOP'">
                <Carousel :images="element.images" />
            </template>
            <template v-else-if="isMobile && element.device === 'MOBILE'">
                <Carousel :images="element.images" />
            </template>
            <template v-else-if="element.device === 'ALL'">
                <Carousel :images="element.images" />
            </template>
        </template>
        <template v-else-if="element.element_type === 'STUDIO_DETAILS'">
            <StudioDetails :currentStudio="currentStudio" />
        </template>
        <template v-else-if="element.element_type === 'PRODUCTS'">
            <StudioProducts :currentStudio="currentStudio" />
        </template>
        <template v-else-if="element.element_type === 'STUDIO_DETAILS_PRODUCTS'">
            <div class="studio-details-products-wrapper">
                <div class="studio-details-products-main">
                    <StudioDetails :currentStudio="currentStudio" />
                    <StudioProducts :currentStudio="currentStudio" />
                </div>
            </div>
        </template>
        <template v-else-if="element.element_type === 'STUDIO_SEARCH'">

     
    </template>
    <template v-else-if="element.element_type === 'STUDIO_SEARCH_MODAL'">
      <Button
        @click="showModalOption"
        :text="elementText"
        :styled="element.element_sub_type"
      />
      <Modal modalLarge="true" v-if="isModalOption">
        <template #body>
          <span class="modal-close" @click="closeModalOption">
            <CloseIcon />
          </span>
          <div class="studio-search-modal">
>>>>>>> develop
            <Search />
          </div>
        </template>
      </Modal>
    </template>
    <template v-else-if="element.element_type === 'BUTTON_LINK'">
      <router-link
        :to="{
          path: '/' + elementSource,
          query: queryParams,
          hash: elementSourceHash,
        }"
      >
        <Button :text="elementText" :styled="element.element_sub_type" />
      </router-link>
    </template>
    <template v-else-if="element.element_type === 'VIEW_PRICING_LINK'">
      <template v-if="displayElementRoute">
        <router-link
          :to="{
            path: '/' + elementSource,
            query: queryParams,
            hash: displayElementSourceHash,
          }"
        >
          <Button :text="elementText" :styled="element.element_sub_type" />
        </router-link>
      </template>
    </template>
    <template v-else-if="element.element_type === 'BUTTON_EXTERNAL_LINK'">
      <a :href="elementSource" target="_blank">
        <Button :text="elementText" :styled="element.element_sub_type" />
      </a>
    </template>
    <template v-else-if="element.element_type === 'IMAGE'">
      <template v-if="isDesktop && element.device === 'DESKTOP'">
        <Image
          :src="element.element_source"
          :text="element.element_alt"
          :imageStyled="element.element_sub_type"
        />
      </template>
      <template v-else-if="isMobile && element.device === 'MOBILE'">
        <Image
          :src="element.element_source"
          :text="element.element_alt"
          :imageStyled="element.element_sub_type"
        />
      </template>
      <template v-else-if="element.device === 'ALL'">
        <Image
          :src="element.element_source"
          :text="element.element_alt"
          :imageStyled="element.element_sub_type"
        />
      </template>
    </template>
    <template v-else-if="element.element_type === 'MODAL_VIDEO'">
      <template v-if="isDesktop && element.device === 'DESKTOP'">
        <IframeVideoCard
          :src="element.element_poster"
          :text="element.element_alt"
          :videoSource="element.element_source"
        />
      </template>
      <template v-else-if="isMobile && element.device === 'MOBILE'">
        <IframeVideoCard
          :src="element.element_poster"
          :text="element.element_alt"
          :videoSource="element.element_source"
        />
      </template>
      <template v-else-if="element.device === 'ALL'">
        <IframeVideoCard
          :src="element.element_poster"
          :text="element.element_alt"
          :videoSource="element.element_source"
        />
      </template>
    </template>
    <template
      v-else-if="
        element.element_type === 'CAROUSEL' && element.images.length > 0
      "
    >
      <template v-if="isDesktop && element.device === 'DESKTOP'">
        <Carousel :images="element.images" />
      </template>
      <template v-else-if="isMobile && element.device === 'MOBILE'">
        <Carousel :images="element.images" />
      </template>
      <template v-else-if="element.device === 'ALL'">
        <Carousel :images="element.images" />
      </template>
    </template>
    <template v-else-if="element.element_type === 'STUDIO_DETAILS'">
      <StudioDetails :currentStudio="currentStudio" />
    </template>
    <template v-else-if="element.element_type === 'PRODUCTS'">
      <StudioProducts :currentStudio="currentStudio" />
    </template>
    <template v-else-if="element.element_type === 'STUDIO_DETAILS_PRODUCTS'">
      <div class="studio-details-products-wrapper">
        <div class="studio-details-products-main">
          <StudioDetails :currentStudio="currentStudio" />
          <StudioProducts :currentStudio="currentStudio" />
        </div>
      </div>
    </template>
    <template v-else-if="element.element_type === 'STUDIO_SEARCH'">
      <Search />
    </template>
    <template v-else-if="element.element_type === 'ADDRESS'">
      <Text
        :class="[
          'element-text ',
          element.element_text_alingment &&
            'text-align-' + element.element_text_alingment,
          element.element_text_size && 'font-size-' + element.element_text_size,
          isMobile &&
            element.element_text_mobile_size &&
            'font-size-mobile-' + element.element_text_mobile_size,
        ]"
        :text="elementText"
        :type="element.element_sub_type"
        :style="[element.element_style]"
      />
      <template v-if="currentStudio">
        <LocationAddress :currentStudio="currentStudio" />
      </template>
    </template>
    <template v-else-if="element.element_type === 'WORKING_HOURS'">
      <Text
        :class="[
          'element-text ',
          element.element_text_alingment &&
            'text-align-' + element.element_text_alingment,
          element.element_text_size && 'font-size-' + element.element_text_size,
          isMobile &&
            element.element_text_mobile_size &&
            'font-size-mobile-' + element.element_text_mobile_size,
        ]"
        :text="elementText"
        :type="element.element_sub_type"
        :style="[element.element_style]"
      />
      <template v-if="currentStudio?.schools_details?.hours">
        <WorkingHours :hours="currentStudio?.schools_details?.hours" />
      </template>
    </template>
    <template v-else-if="element.element_type === 'GOOGLE_MAP'">
      <LocationMap :currentStudio="currentStudio" />
    </template>
    <template v-else-if="element.element_type === 'AMENITIES'">
      <Amenities :amenities="currentStudio?.schools_details?.amenities" />
    </template>
    <template v-else-if="element.element_type === 'TECHNOLOGIES'">
      <Amenities :amenities="currentStudio?.schools_details?.technology" />
    </template>
    <template v-else-if="element.element_type === 'MINDBODY_SCHEDULE_WIDGET'">
      <ScheduleWidget :widget="currentStudio?.mindbody_schedule_widget" />
    </template>
    <template v-else-if="element.element_type === 'REFERRIZER_WIDGET'">
      <ReferrizerWidget :widget="currentStudio?.referrizer_widget" />
    </template>
    <template v-else-if="element.element_type === 'FAQS'">
      <FAQs :type="element.element_sub_type" />
    </template>
    <template v-else-if="element.element_type === 'PUNCHES'">
      <Punches />
    </template>
    <template v-else-if="element.element_type === 'CLASS_STRUCTURE'">
      <ClassStructure />
    </template>
    <template v-else-if="element.element_type === 'INSTRUCTORS'">
      <Instructors :currentStudio="currentStudio" />
    </template>
    <template v-else-if="element.element_type === 'CONTACT_FORM'">
      <ContactForm />
    </template>
    <template v-else-if="element.element_type === 'FIGHTER'">
      <Fighter />
    </template>
    <template v-else-if="element.element_type === 'LINK'">
      <router-link
        :to="{
          path: '/' + elementSource,
          query: queryParams,
          hash: elementSourceHash,
        }"
        class="router-link btn-link"
      >
        <span class="btn-link-icon">
          <ArrowRightSmallIcon />
        </span>
        {{ element.element_text }}
      </router-link>
    </template>
    <template v-else-if="element.element_type === 'STUDIO_SCHEDULE_WIDGET'">
      <StudioSchedule :currentStudio="currentStudio" />
    </template>
    <template v-else-if="element.element_type === 'LOADER'">
      <Loader />
    </template>
    <template v-else>
      <template v-if="isDesktop && element.device === 'DESKTOP'">
        <div>{{ element.element_text }}</div>
      </template>
      <template v-else-if="isMobile && element.device === 'MOBILE'">
        <div>{{ element.element_text }}</div>
      </template>
      <template v-else-if="element.device === 'ALL'">
        <div>{{ element.element_text }}</div>
      </template>
    </template>
  </ElementContaier>
</template>
