<script setup>
    import Text from "@/components/elements/Text.vue";
    import Button from "@/components/elements/Button.vue";
    import {ref, defineProps, onMounted, computed} from "vue";
    import {getProductsByStudioId, customerInfoLead, getfreeclassByStudioId} from "../../mixins/api";
    import LeadCaptureModal from "@/components/elements/LeadCaptureModal.vue";
    import Modal from "./Modal.vue";
    import CloseIcon from "../../assets/svg/CloseIcon.vue";
    import GetStarted from "./GetStarted.vue";
    import {useRoute, useRouter} from "vue-router";
    import { triggerTrackEvents } from "../../mixins/events_handler";

    const route = useRoute();
    const queryParams = route.query;
    const router = useRouter();

    const props = defineProps({
        element: {
            type: Object,
        },
        currentStudio: {
            type: Object
        }
    });
    const checkoutUrl = process.env.VUE_APP_CHECKOUT_URL;
    const FreeclassUrl = process.env.VUE_APP_FreeclassUrl_URL;
    const products = ref([]);
    const freeclass = ref([]);
    const buildTrackEvents = (product)=>{        
        const eventname = route?.name.toLowerCase() + '_cta_' + product.internal_product_id
        triggerTrackEvents(route.name, product.description, eventname)
         
    }
    
    const isModalOption = ref(false);
    const showModalOption = (product=null) => {
        buildTrackEvents(product)
        isModalOption.value = true;
    };
    const closeModalOption = () => {
        isModalOption.value = false;
      //  isModalOption.value = false;
        showModal.value = false; // add this line only in existing method for closeModalOption
        selectedProduct.value = null;
    };

    // Current Studio Id
    const studioId = computed(() => {
        return props?.currentStudio?.ID
            ? props?.currentStudio?.ID
            : 0;
    });
//  const CustomerLeadInfo = () => {
//   var customer={first_name:"Test through JSON",
//             last_name:"Test through JSON",
//             telephone:"996633225588",
//             email:"Test@through.JSON",
//             email_subscription:false,
//             sms_subscription:false};
// customerInfoLead(customer, 757).then((response) => {
//                alert(response);
//         });
        
//     };
    const getProducts = () => {
        console.log('currentStudio', props?.currentStudio, studioId.value)
        getProductsByStudioId(studioId.value).then((response) => {
          products.value = response;
        });
    };

    const getfreeclass= () => {
        console.log('currentStudio', props?.currentStudio, studioId.value)
        getfreeclassByStudioId(studioId.value).then((response) => {
          freeclass.value = response;
        });
    };
   const redirectTogetfreeclassUrl=()=> {
   
     var url = FreeclassUrl + props?.currentStudio?.url_slug ;
     
      window.location.href = url;
   
    }
    const containsFreeClass = (productname) => {

      const mainStringLower = productname.toLowerCase();
      return mainStringLower.includes("free")

    };
    const redirectToCheckout = (product) => {
       /* if(props?.currentStudio?.url_slug == 'oldsmar')
        {
            if(product.internal_product_id =='3classes')
                window.location.href ="https://www.clubready.com/JoinUs/14333/612008";
            if(product.internal_product_id =='5classes')
                window.location.href ="https://www.clubready.com/JoinUs/14333/612009";
        }*/
     //   else{
        buildTrackEvents(product)
      const params = {
        product_id: product.ckt_products_id,
        ...queryParams
      }
      const paramsQuery = Object
            .keys(params)
            .map(value => `${value}=${encodeURIComponent(params[value])}`)
            .join('&');
            return checkoutUrl + props?.currentStudio?.url_slug +
            '?' + paramsQuery;
   //   window.location.href = checkoutUrl + props?.currentStudio?.url_slug +
       //   '?' + paramsQuery;
 //   }
    };
   
    // const openLeadModal=(pos_system_url)=> {
    //   PosSystemUrl.value = pos_system_url; 
    //   showModal.value = true;
    // ;
    // };
    // const closeLeadModal = () => {
    //     showModal.value = false; // add this line only in existing method for closeModalOption
    // };
    onMounted(() => {
        getProducts();
        getfreeclass();
       // CustomerLeadInfo();
    });

    // const showModal = ref(false); //add this
    // const PosSystemUrl = ref(""); 

</script>

<script>
export default {
  data() {
    return {
      PosSystemUrl: '',
      showModal: false
    };
  },
  methods: {
    openLeadModal(pos_system_url) {
      this.PosSystemUrl = pos_system_url; // Replace with your actual calculation
      this.showModal = true; // Show the modal
    },
    closeLeadModal() {
      this.showModal = false; // Hide the modal
    }
  }
};
</script>
<template>
   <!-- <section class="section-container col-100 alignCenter" v-if="currentStudio?.url_slug === 'oldsmar'">
     <div class="element-contaier" style="margin: 0px;">
       <div class="studio-details-products-wrapper">
         <div class="studio-details-products-main">
      
           <div class="classes_main_wrp">
             <div class="classes_items_wrp">
               <div class="classes_item_card">
                 <div class="d-flex align-items-center">
                   <div class="classes_item_price">
                     <del>$90.00 <span class="sr-only">(Previous price: $90.00)</span>
                     </del>
                     <div class="pricefix">
                       <span class="currency">$</span>15.00
                     </div>
                   </div>
                   <div class="classes_item_title">
                     <h5 class="">Grand Re-Opening Offer - 3 Classes </h5>
                     <p class="">
                       <span>3 Kickboxing Classes ($90 Value) for $15.00</span>
                     </p>
                   </div>
                 </div>
                 <div class="classes_item_cta">
                   <button type="button" class="btn btn-primary" onclick=' window.location.href ="https://www.clubready.com/JoinUs/14333/612008"'> Purchase</button>
                 </div>
               </div>
             </div>
             <div class="classes_items_wrp">
               <div class="classes_item_card">
                 <div class="d-flex align-items-center">
                   <div class="classes_item_price">
                     <del>$150.00 <span class="sr-only">(Previous price: $150.00)</span>
                     </del>
                     <div class="pricefix">
                       <span class="currency">$</span>20.00
                     </div>
                   </div>
                   <div class="classes_item_title">
                     <h5 class="">Grand Re-Opening Offer - 5 Classes </h5>
                     <p class="">
                       <span>5 Kickboxing Classes ($150 Value) for $20.00</span>
                     </p>
                   </div>
                 </div>
                 <div class="classes_item_cta">
                   <button type="button" class="btn btn-primary" onclick=' window.location.href ="https://www.clubready.com/JoinUs/14333/612009"'> Purchase</button>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
    
   </section> -->
   <div class="classes_main_wrp" :class="mode-light" v-if="currentStudio?.landing_page_freeclass_sw && freeclass.length > 0 ">
        <div class="classes_items_wrp" v-for="(product, productIndex) in freeclass" :key="productIndex">
            <div class="classes_item_card" v-if="containsFreeClass(product.type) && containsFreeClass(product.category)">
                <div class="d-flex align-items-center">
                    <div class="classes_item_price">
                      <del>$30.00
                        <span class="sr-only"></span></del>
                        <div class="pricefix">
                            <span class="currency">$</span>0.00
                        </div>
                    </div>
                    <div class="classes_item_title">
                        <Text text ="Free Class" type="H5"/>
                        <Text  text ="1 Kickboxing Class ($30 Value) for FREE" />
                    </div>
                </div>
                <div class="classes_item_cta">
                  <button type="button" class="btn btn-primary" @click=" redirectTogetfreeclassUrl();">Get Free class</button>
                </div>
            </div>
        </div>
    </div>
   <div class="classes_main_wrp" :class="mode-light" v-if="products.length > 0 && currentStudio?.pos_system === 'Clubready'">
        <div class="classes_items_wrp" v-for="(product, productIndex) in products" :key="productIndex">
            <div class="classes_item_card">
                <div class="d-flex align-items-center">
                    <div class="classes_item_price">
                        <del>${{product.value}}
                            <span class="sr-only">(Previous price: ${{product.value}})</span></del>
                        <div class="pricefix">
                            <span class="currency">$</span>{{product.price}}
                        </div>
                    </div>
                    <div class="classes_item_title">
                        <Text :text="product.button_description" type="H5"/>
                        <Text :text="product.description"/>
                    </div>
                </div>
                <div class="classes_item_cta">
                  <button type="button" class="btn btn-primary" @click=" openLeadModal(product.pos_system_url)">Purchase</button>
                </div>
            </div>
        </div>
    </div>
  <div class="classes_main_wrp" :class="mode-light" v-if="products.length > 0  && currentStudio?.pos_system !== 'Clubready'">
        <div class="classes_items_wrp" v-for="(product, productIndex) in products" :key="productIndex">
            <div class="classes_item_card">
                <div class="d-flex align-items-center">
                    <div class="classes_item_price">
                        <del>${{product.value}}
                            <span class="sr-only">(Previous price: ${{product.value}})</span></del>
                        <div class="pricefix">
                            <span class="currency">$</span>{{product.price}}
                        </div>
                    </div>
                    <div class="classes_item_title">
                        <Text :text="product.button_description" type="H5"/>
                        <Text :text="product.description"/>
                    </div>
                </div>
                <div class="classes_item_cta">
                  <Button text="Purchase" @click="openLeadModal(redirectToCheckout(product))"  v-if="studioId"/>
                  <Button text="Purchase" @click="showModalOption(product)" v-else />
                </div>
            </div>
        </div>
    </div>
    <Modal modalLarge="true" v-if="isModalOption">
        <template #body>
            <span class="modal-close" @click="closeModalOption">
                <CloseIcon/>
            </span>
            <GetStarted :redirectedToCheckout="true" :redirectedToStudio="false" />
        </template>
    </Modal>
    <!-- <button @click="showModal = true" class="btn btn-primary">Open Lead Capture Modal</button> -->
  <LeadCaptureModal v-if="showModal"  :show="showModal" :PosSystemUrl="PosSystemUrl" :currentStudio ="currentStudio" @close="closeLeadModal" />
  <!-- redirectToUrl(product.pos_system_url) -->
 
</template>